







import Vue from "vue";
import axios from "axios";

import { web_backend_url } from "@/config.ts";

export default Vue.extend({
  name: "About",
  data: () => ({
    backend_status: null,
  }),
  mounted: function () {
    this.checkBackendStatus();
  },
  methods: {
    async checkBackendStatus() {
      const url = `${web_backend_url}/ping`;
      const params = {
        dependencies: false,
      };
      this.backend_status = await axios
        .get(url, { params: params })
        .then((r) => {
          return r.data;
        });
    },
  },
});
